import React, { Fragment, useEffect, useRef, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchFilteredPayments } from "../store/payments";
import Datepicker from "react-tailwindcss-datepicker";
import { AnimatePresence, motion } from "framer-motion";
import { selectPayer } from "../store/payer";
import { selectFeatureValue } from "../store/features";
import CurrencyInput, { CurrencyInputOnChangeValues } from "react-currency-input-field";
import { BatchStatusSequence } from "@corechain-technologies/types";
import { classNames } from "../utils/classNames";
import { trpc } from "../utils/trpc";
import currency from "currency.js";

interface FilterControlsProps {
    view: string;
}

const FilterControls: React.FC<FilterControlsProps> = ({ view }) => {
    const dispatch = useAppDispatch();
    const payerId = useAppSelector(selectPayer)?.id;
    if (!payerId) return;
    const features = useAppSelector(selectFeatureValue("DEPLOY_TARGET"));
    const [startAmountValue, setStartAmountValue] = useState<string>("");
    const [startAmountValues, setStartAmountValues] = useState<CurrencyInputOnChangeValues>();
    const [endAmountValue, setEndAmountValue] = useState<string>("");
    const [endAmountValues, setEndAmountValues] = useState<CurrencyInputOnChangeValues>();
    const [range, setRange] = useState(true);
    const [payee, setPayee] = useState("");
    const [status, setStatus] = useState("All");
    const [createdDate, setCreatedDate] = useState({
        startDate: null,
        endDate: null,
    });
    const [statusDate, setStatusDate] = useState({
        startDate: null,
        endDate: null,
    });
    const filterValues: Parameters<typeof trpc.filteredPayments.query>[0] = {
        payerId,
    };

    const handleCreatedDateValueChange = (newValue: any) => {
        setCreatedDate(newValue);
    };
    const handleStatusDateValueChange = (newValue: any) => {
        setStatusDate(newValue);
    };

    if (payee !== "") filterValues.payee = payee;
    if (payee === "") delete filterValues.payee;
    if (status !== "All") filterValues.status = status.toUpperCase();
    if (status === "All") delete filterValues.status;
    if (startAmountValues?.float && !range)
        filterValues.amount = [
            BigInt(currency(startAmountValues?.value ?? 0).intValue),
            BigInt(currency(startAmountValues?.value ?? 0).intValue),
        ];
    if (!startAmountValues?.float && !range) delete filterValues.amount;
    if (startAmountValues?.float != null && endAmountValues?.float != null && range)
        filterValues.amount = [
            BigInt(currency(startAmountValues?.float ?? 0).intValue),
            BigInt(currency(endAmountValues?.float ?? 0).intValue),
        ];
    if (!startAmountValues?.float && !range) delete filterValues.amount;
    if (!startAmountValues?.float == null && !endAmountValues?.float == null && range)
        delete filterValues.amount;
    if (createdDate.startDate && createdDate.endDate) {
        const createdStartDate = new Date(createdDate.startDate);
        createdStartDate.setUTCHours(0, 0, 0, 0);
        const createdEndDate = new Date(createdDate.endDate);
        createdEndDate.setUTCHours(23, 59, 59, 999);
        filterValues.originationDate = [createdStartDate, createdEndDate];
    }
    if (!createdDate.startDate && !createdDate.endDate) delete filterValues.originationDate;
    if (statusDate.startDate && statusDate.endDate) {
        const statusStartDate = new Date(statusDate.startDate);
        statusStartDate.setUTCHours(0, 0, 0, 0);
        const statusEndDate = new Date(statusDate.endDate);
        statusEndDate.setUTCHours(23, 59, 59, 999);
        filterValues.statusDate = [statusStartDate, statusEndDate];
    }
    if (!statusDate.startDate && !statusDate.endDate) delete filterValues.statusDate;

    useEffect(() => {
        dispatch(fetchFilteredPayments(filterValues));
    }, [payee, status, createdDate, statusDate, startAmountValues, endAmountValues, range]);

    if (view === "BATCHES" && features === "DEVELOPMENT") {
        return (
            <tr className="bg-gray-50 cursor-pointer group hover:bg-gray-50 card text-gray-600">
                <td className="pl-4 sm:pl-6 lg:pl-8 py-4 pr-1 text-left whitespace-nowrap text-sm  border-b border-gray-300 border-spacing-0">
                    {/* <label>
                      Range */}
                    <input
                        type="text"
                        name="originatingDate"
                        id="originatingDate"
                        className="rounded-md border-0 w-2/3 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-branded-primary sm:text-sm sm:leading-6"
                        placeholder="11/23/2024"
                    />
                    {/* </label> */}
                </td>
                <td className="eleven21:pl-1 py-4 whitespace-nowrap text-sm  border-b border-gray-300 border-spacing-0">
                    {/* <label>
                        File Name: */}
                    <input
                        type="text"
                        name="fileName"
                        id="fileName"
                        className="rounded-md w-full border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-branded-primary sm:text-sm sm:leading-6"
                        placeholder="pif.txt"
                    />
                    {/* </label> */}
                </td>
                <td className="pr-6 eleven21:pl-6 py-4 text-right whitespace-nowrap text-sm border-b border-gray-300 border-spacing-0">
                    <label>
                        {range && <span className="mr-0.5">start:</span>}
                        <input
                            type="text"
                            name="amount"
                            id="amount"
                            className="rounded-md ml-1.5 w-4/5  border-0 py-1.5 text-right pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-branded-primary sm:text-sm sm:leading-6"
                            placeholder="e.g. $100.00"
                        />
                    </label>
                </td>
                <td className="hidden sm:table-cell print:table-cell text-center pr-6 eleven21:pl-1 py-4 whitespace-nowrap text-sm border-b border-gray-300 border-spacing-0">
                    <label>
                        <StatusFilterSelect status={status} setStatus={setStatus} key="1" />
                    </label>
                </td>
                <td className="hidden eight70:table-cell print:table-cell pl-0 pr-4 sm:pr-6 lg:pr-8 py-4 text-right whitespace-nowrap text-sm border-b border-gray-300 border-spacing-0"></td>
            </tr>
        );
    } else {
        return (
            // payments
            <AnimatePresence>
                <tr key="payment manual" className="group bg-gray-50 hover:cursor-pointer card text-gray-600">
                    <td
                        className={`pl-4 py-4 text-right whitespace-nowrap text-sm ${
                            !range && "border-b border-gray-200 border-spacing-0"
                        }`}
                    >
                        <div className="max-w-28">
                            {" "}
                            {/* <OriginationDatePickerContainer /> */}
                            <Datepicker
                                value={createdDate}
                                onChange={handleCreatedDateValueChange}
                                primaryColor="green"
                                placeholder="Date"
                            />
                        </div>
                    </td>
                    <td
                        className={`py-4 whitespace-nowrap text-sm ${
                            !range && "border-b border-gray-200 border-spacing-0"
                        }`}
                    >
                        <input
                            type="text"
                            name="payee"
                            id="payee"
                            className="rounded-md w-full border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-branded-primary sm:text-sm sm:leading-6"
                            placeholder="e.g Supplier, Inc."
                            value={payee}
                            onChange={(e) => setPayee(e.currentTarget.value)}
                        />
                    </td>
                    <td
                        className={`relative py-4 text-right whitespace-nowrap text-sm ${
                            !range && "border-b border-gray-200 border-spacing-0"
                        }`}
                    >
                        <label className="flex justify-end">
                            {range && (
                                <motion.span
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="pt-1.5 pr-1"
                                >
                                    min:
                                </motion.span>
                            )}
                            <CurrencyInput
                                className="rounded-md w-3/5  border-0 py-1.5 text-right pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-branded-primary sm:text-sm sm:leading-6"
                                placeholder="e.g $100.00"
                                value={startAmountValue}
                                onValueChange={(value, name, values) => {
                                    setStartAmountValue(value ?? "");
                                    setStartAmountValues(values);
                                    // console.log(value, name, values);
                                }}
                                allowNegativeValue={false}
                                prefix="$"
                            />
                        </label>
                        {!range && <RangeSwitch range={range} setRange={setRange} />}
                    </td>
                    <td
                        className={`hidden pl-2 py-4 whitespace-nowrap text-sm md:table-cell print:table-cell w-44 ${
                            !range && "border-b border-gray-200 border-spacing-0"
                        }`}
                    >
                        <label
                            htmlFor="method"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        ></label>
                        <select
                            id="method"
                            name="method"
                            disabled
                            className="block w-1/2 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue="N\A"
                        >
                            <option>N\A</option>
                        </select>
                    </td>
                    <td
                        className={`hidden pl-2 py-4 whitespace-nowrap text-sm md:table-cell print:table-cell w-44 ${
                            !range && "border-b border-gray-200 border-spacing-0"
                        }`}
                    >
                        <label>
                            <StatusFilterSelect status={status} setStatus={setStatus} key="2" />
                        </label>
                    </td>
                    <td
                        className={`pl-0  pr-4 py-4 flex justify-end text-right whitespace-nowrap text-sm  ${
                            !range && "border-b border-gray-200 border-spacing-0"
                        }`}
                    >
                        {/* <StatusDatePickerContainer /> */}
                        <div className="max-w-28">
                            <Datepicker
                                value={statusDate}
                                onChange={handleStatusDateValueChange}
                                primaryColor="green"
                                placeholder="Date"
                            />
                        </div>
                    </td>
                </tr>
                {range && (
                    <motion.tr
                        initial={{ y: 36 }}
                        animate={{ y: 0 }}
                        exit={{ opacity: 0, transition: { duration: 0.08 } }}
                        key="range"
                        className="group bg-gray-50 hover:cursor-pointer card text-gray-600"
                    >
                        <td className="pl-4 pr-6 pb-4 whitespace-nowrap text-sm border-b border-gray-200 border-spacing-0" />
                        <td className="pl-4 pr-6 pb-4 whitespace-nowrap text-sm border-b border-gray-200 border-spacing-0" />
                        <td className="relative pb-4 text-right whitespace-nowrap text-sm border-b border-gray-200 border-spacing-0">
                            <label className="flex justify-end">
                                {range && (
                                    <motion.span
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        className="pt-1.5 pr-1"
                                    >
                                        max:
                                    </motion.span>
                                )}
                                <CurrencyInput
                                    className="rounded-md w-3/5  border-0 py-1.5 text-right pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-branded-primary sm:text-sm sm:leading-6"
                                    placeholder="e.g $100.00"
                                    value={endAmountValue}
                                    onValueChange={(value, name, values) => {
                                        setEndAmountValue(value ?? "");
                                        setEndAmountValues(values);
                                        // console.log(value, name, values);
                                    }}
                                    allowNegativeValue={false}
                                    prefix="$"
                                />
                            </label>
                            {/* {range && <RangeSwitch range={range} setRange={setRange} />} */}
                        </td>
                        <td className="hidden text-center pl-0 xl:pl-6 pr-6 pb-4 whitespace-nowrap text-sm md:table-cell border-b border-gray-200 border-spacing-0 print:table-cell w-48" />
                        <td className="pl-0 pr-4 pb-4 text-right whitespace-nowrap text-sm border-b border-gray-200 border-spacing-0" />
                        <td className="pl-0 pb-4 text-right whitespace-nowrap text-sm border-b border-gray-200 border-spacing-0" />
                    </motion.tr>
                )}
            </AnimatePresence>
        );
    }
};

const statuses = ["All"]
    .concat(BatchStatusSequence)
    .map((statusName) => statusName.charAt(0).toUpperCase() + statusName.slice(1).toLowerCase());

// const statuses = ["All"].concat(BatchStatusSequence).map((status, i) => ({
//     id: i + 1,
//     name: status.charAt(0).toUpperCase() + status.slice(1).toLowerCase(),
// }));

interface StatusFilterSelectProps {
    status: string;
    setStatus: (status: string) => void;
}

const StatusFilterSelect: React.FC<StatusFilterSelectProps> = ({ status, setStatus }) => {
    return (
        <Listbox value={status} onChange={setStatus}>
            {({ open }) => (
                <>
                    {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                        Assigned to
                    </Listbox.Label> */}
                    <div className="relative">
                        <Listbox.Button className="w-32 relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-branded-primary sm:text-sm sm:leading-6">
                            <span className={`block truncate pl-1 ${status === "All" && "text-gray-500"}`}>
                                {status}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {statuses.map((status, i) => (
                                    <Listbox.Option
                                        key={`${status}-${i}`}
                                        className={({ active }) =>
                                            classNames(
                                                active ? "bg-branded-primary" : "",
                                                "relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900",
                                            )
                                        }
                                        value={status}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={classNames(
                                                        selected ? "font-semibold" : "font-normal",
                                                        "block truncate",
                                                    )}
                                                >
                                                    {status}
                                                </span>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? "text-white" : "text-cc-core-600",
                                                            "absolute inset-y-0 right-0 flex items-center pr-4",
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
};

interface RangeProps {
    range: boolean;
    setRange: (range: boolean) => void;
}

const RangeSwitch: React.FC<RangeProps> = ({ range, setRange }) => {
    return (
        <>
            <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                type="button"
                className="absolute bottom-0 right-0 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:text-branded-primary"
                onClick={() => setRange(!range)}
            >
                {range ? "Single Value?" : "Range?"}
            </motion.button>
        </>
    );
};

export default FilterControls;
