import { Catalog, msg } from "@hi18n/core";
import type { Vocabulary } from ".";

const catalog = new Catalog<Vocabulary>({
    // "<Translation ID>": msg(<translated message>),
    "app/error/404": msg("404"),
    "app/error/404-title": msg("Page not found."),
    "app/error/404-subtitle": msg("Sorry, we couldn't find the page you're looking for."),
    "app/error/404-button": msg("return to app"),
    "app/error/unknown": msg("An unknown error has occurred"),
    "app/error/unknown-title": msg("Error"),
    "app/error/unknown-subtitle": msg("An unknown error has occurred"),
    "app/error/unknown-button": msg("return to app"),
    "app/greeting": msg("Welcome to Scanco Payments Automation"),
    "app/usage": msg("By accessing or using Scanco Payments Automation you agree to the"),
    "app/terms-of-service": msg("Terms of Service"),
    "app/privacy-policy": msg("Privacy Policy"),
    "app/title": msg("Scanco Payments Automation"),
    "app/sidebar/logo": msg("scancoLogoOnly.png"),
    "app/sidebar/line1": msg("Payments"),
    "app/sidebar/line2": msg("Automation"),
    "app/nav/home": msg("Home"),
    "app/nav/payments": msg("Payments"),
    "app/nav/vendors": msg("Vendors"),
    "app/nav/receivables": msg("Receivables"),
    "app/nav/payers": msg("Payers"),
    "app/nav/reports": msg("Reports"),
    "app/nav/settings": msg("Settings"),
    "app/nav/help": msg("Help"),
    "app/notifications": msg("View Notifications"),
    "app/notifications/duplicateFile": msg("Duplicate file uploads are not permitted."),
    "app/notifications/uploadSuccess": msg("Your file was successfully uploaded."),
    "app/notifications/uploadError": msg("{namespace} failed to upload; please contact support"),
    "app/notifications/uploadFetchError": msg(
        "Payment set {batchId} was not processed succesfully; please contact support",
    ),
    "app/notifications/errorDefault": msg("file upload error"),
    "activityTable/columnHeaders/amount": msg("Amount"),
    "activityTable/columnHeaders/uploadedFileName": msg("File Name"),
    "activityTable/columnHeaders/date": msg("Date"),
    "activityTable/columnHeaders/originationDate": msg("Origination Date"),
    "activityTable/columnHeaders/createdDate": msg("Created Date"),
    "activityTable/columnHeaders/statusDate": msg("Last Updated"),
    "activityTable/columnHeaders/paymentIdReceivables": msg("Payment ID"),
    "activityTable/columnHeaders/fileId": msg("File ID"),
    "activityTable/columnHeaders/method": msg("Method"),
    "activityTable/columnHeaders/payee": msg("Payee"),
    "activityTable/columnHeaders/payer": msg("Payer"),
    "activityTable/columnHeaders/status": msg("Status"),
    "activityTable/columnHeaders/transaction": msg("Transaction"),
    "batchDetail/numberPayments": msg("Number of Payments"),
    "batchDetail/originatingAccount": msg("Originating Account"),
    "batchDetail/totalAmount": msg("Total Amount"),
    "comparisonCards/vendorsByVolume": msg("Top Vendors by Volume"),
    "comparisonCards/paymentByMethod": msg("Payment Volume by Method"),
    "comparisonCards/vendorsByPayments": msg("Top Vendors by Total Payments"),
    "comparisonCards/payersByVolume": msg("Top Payers by Volume"),
    "comparisonCards/volumeReceivedByMethod": msg("Payment Volume by Method"),
    "comparisonCards/payersByPayments": msg("Top Payers by Total Payments"),
    currency: msg("USD"),
    "dummyListing/duplicate": msg("You have uploaded a duplicate file"),
    "dummyListing/fileUploadError": msg("The file you uploaded could not be parsed"),
    "header/greeting": msg("Welcome, {name}."),
    "header/verified": msg("Verified Account"),
    "header/sr/company": msg("Company"),
    "header/sr/accountStatus": msg("Account status"),
    "insightCards/lifetimeTotalPaid": msg("Lifetime Total Payments"),
    "insightCards/lifetimeVolumePaid": msg("Lifetime Payment Volume"),
    "insightCards/notSettled": msg("Pending Payments"),
    "insightCards/lifetimeTotalReceived": msg("Lifetime Payments Received"),
    "insightCards/lifetimeVolumeReceived": msg("Lifetime Volume Received"),
    "insightCards/notReceived": msg("In Progress"),
    "payerList/title": msg("Payers"),
    "payerList/companyName": msg("Company Name"),
    "payerList/lastPayment": msg("Last Payment"),
    "payerList/lastPaymentInline": msg("Last payment:"),
    "payerList/enrolled": msg("Enrolled"),
    "payerList/status": msg("Status"),
    "payerList/role": msg("Role"),
    "payerList/amountReceivedInline": msg("Amount received:"),
    "payerList/totalReceivedInline": msg("Total received:"),
    "payerPaymentHistory/paymentWithTotalValue": msg("payment with total value"),
    "payerPaymentHistory/paymentsWithTotalValue": msg("payments with total value"),
    "paymentsTimeline/header": msg("Aggregate Payment Volume Last {maxDataRange} Payment Dates"),
    "placeholder/header": msg("Refreshing Content"),
    "placeholder/status": msg("Loading..."),
    "placeholder/currency": msg("... USD"),
    "placeholder/notApplicable": msg("... N/A"),
    "paymentDetail/paymentTo": msg("Payment to"),
    "paymentDetail/originalBatchId": msg("Source File Id:"),
    "paymentDetail/lifecycleMarkers/flagged": msg("Flagged for review"),
    "paymentDetail/lifecycleMarkers/rejected": msg("Rejected by Approver"),
    "paymentDetail/lifecycleMarkers/failed": msg("Payment was unsuccessful"),
    "paymentDetail/lifecycleMarkers/created": msg("File created"),
    "paymentDetail/lifecycleMarkers/approved": msg("Approval granted"),
    "paymentDetail/lifecycleMarkers/scheduled": msg("Delivery scheduled"),
    "paymentDetail/lifecycleMarkers/pending": msg("Transmitting data"),
    "paymentDetail/lifecycleMarkers/delivered": msg("Received for processing"),
    "paymentDetail/lifecycleMarkers/accepted": msg("Accepted for settlement"),
    "paymentDetail/lifecycleMarkers/settled": msg("Settled successfully"),
    "paymentDetail/lifecycleMarkers/reverted": msg("Payment reverted"),
    "receivables/title": msg("Payments Received"),
    "receivablesDetail/paymentFrom": msg("Payment from"),
    "receivablesDetail/receivingAccount": msg("Receiving Account"),
    "receivablesDetail/totalAmount": msg("Total Amount"),
    "receivablesDetail/paymentId": msg("Payment Reference"),
    "privacyPolicy/title": msg("Privacy Policy"),
    recentActivity: msg("Recent Activity"),
    "reports/title": msg("Reports"),
    "reports/downloadReport": msg("Download report"),
    "reports/lifetimeStatistics": msg("Lifetime Statistics"),
    "reports/paymentsByDate": msg("Aggregate Payments by Date"),
    "reports/vendorLifetime": msg("Vendor Lifetime Report"),
    "search/placeholder": msg("Search transactions"),
    "tabs/paymentsIndividual": msg("Individual Payments"),
    "tabs/paymentSets": msg("Payment Files"),
    "termsOfService/title": msg("Terms of Service"),
    "uploadBox/payment": msg("Click or drag here to upload a payment file"),
    "uploadBox/vendor": msg("Click or drag here to upload a vendor management file"),
    "vendorList/title": msg("Vendors"),
    "vendorList/companyName": msg("Company Name"),
    "vendorList/contactName": msg("Contact name"),
    "vendorList/lastPaid": msg("Last Paid"),
    "vendorList/status": msg("Status"),
    "vendorList/role": msg("Role"),
    "vendorPaymentHistory/paymentWithTotalValue": msg("payment with total value"),
    "vendorPaymentHistory/paymentsWithTotalValue": msg("payments with total value"),
});

export default catalog;
