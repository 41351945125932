import {
    BatchStatusName,
    NachaPaymentInstructionMessage,
    PaymentMethod,
    PaymentMethodName,
} from "@corechain-technologies/types";
import { format } from "date-fns";
// import { Listing, PaymentWithDate } from "../store/listings";
import { PaymentListing } from "../store/payments";
import { calculatePaymentId } from "@corechain-technologies/types";
import { BatchListing } from "../store/listings";

type VendorFields = {
    date: string;
    name: string;
    method: PaymentMethod;
    amount: number;
    quantity: number;
};

function getVendorFields(paymentListings: PaymentListing[], showPaymentMethod: boolean): VendorFields[] {
    // TODO: Filter, then type assert is a temporary fix to avoid payments without created dates
    return paymentListings
        .filter((paymentListing) => paymentListing.createdDate && !paymentListing.rejectedDate)
        .map((paymentListing): VendorFields => {
            return {
                date: format(paymentListing.createdDate as Date, "MM/dd/yy"),
                name: paymentListing.payee,
                method: PaymentMethod.parse(paymentListing.paymentMethod),
                amount: Number(paymentListing.amount),
                quantity: 1,
            };
        });
}

// eslint-disable-next-line
type SortedPaymentsByMethodData = {
    [date: string]: { ACH: number; VIRTUAL_CARD: number; CHECK: number; E_CHECK: number };
};
// eslint-disable-next-line
export type SortedPaymentsByMethod = {
    ACH: number;
    VIRTUAL_CARD: number;
    CHECK: number;
    E_CHECK: number;
    date: string;
    total: number;
};

function getSortedPaymentsData(
    paymentListings: PaymentListing[],
    showPaymentMethod: boolean,
): SortedPaymentsByMethodData {
    const sortedPaymentsData = getVendorFields(paymentListings, showPaymentMethod);
    return sortedPaymentsData.reduce((acc, cur) => {
        if (!cur.method) {
            return acc;
        }
        if (acc[cur.date]) {
            acc[cur.date][cur.method] += cur.amount;
        } else {
            // eslint-disable-next-line
            const payeeData = { ACH: 0, VIRTUAL_CARD: 0, CHECK: 0, E_CHECK: 0 };
            payeeData[cur.method] += cur.amount;
            acc[cur.date] = payeeData;
        }
        return acc;
    }, {} as SortedPaymentsByMethodData);
}

export function formatSortedPaymentsByMethod(
    paymentListings: PaymentListing[],
    showPaymentMethod: boolean,
): SortedPaymentsByMethod[] {
    const sortedPaymentData = getSortedPaymentsData(paymentListings, showPaymentMethod);
    return Object.keys(sortedPaymentData).map((date: string) => {
        const agg = sortedPaymentData[date] as SortedPaymentsByMethod;
        agg.total = agg.ACH + agg.VIRTUAL_CARD + agg.E_CHECK + agg.CHECK;
        agg.date = date;
        return agg;
    });
}

//
//
//
//
//

type TotalPaymentsToVendorsData = { [name: string]: { count: number } };
type TotalPaymentsToVendors = { name: string; count: number };

function getTotalPaymentsToVendorsData(
    paymentListings: PaymentListing[],
    showPaymentMethod: boolean,
): TotalPaymentsToVendorsData {
    const totalPaymentsToVendorsData = getVendorFields(paymentListings, showPaymentMethod);
    return totalPaymentsToVendorsData.reduce((acc, cur) => {
        if (acc[cur.name]) {
            acc[cur.name].count += 1;
        } else {
            const payeeData = { count: 1 };
            acc[cur.name] = payeeData;
        }
        return acc;
    }, {} as TotalPaymentsToVendorsData);
}

export function formatTotalPaymentsToVendors(
    paymentListings: PaymentListing[],
    showPaymentMethod: boolean,
): TotalPaymentsToVendors[] {
    const vendorStats = getTotalPaymentsToVendorsData(paymentListings, showPaymentMethod);
    return Object.keys(vendorStats)
        .map((vendorStat: string) => {
            return { name: vendorStat, count: vendorStats[vendorStat].count };
        })
        .sort((a, b) => b.count - a.count);
}

type VolumeByMethodData = { [method: string]: { amount: number; quantity: number } };
type VolumeByMethod = { method: PaymentMethod; amount: number; quantity: number };

function getVolumeByMethod(
    paymentListings: PaymentListing[],
    showPaymentMethod: boolean,
): VolumeByMethodData {
    const volumeByMethodData = getVendorFields(paymentListings, showPaymentMethod);
    return volumeByMethodData.reduce((acc, cur) => {
        if (acc[cur.method]) {
            acc[cur.method].amount += cur.amount;
            acc[cur.method].quantity += cur.quantity;
        } else {
            const payeeData = { amount: 0, quantity: 1 };
            payeeData.amount += cur.amount;
            acc[cur.method] = payeeData;
        }
        return acc;
    }, {} as VolumeByMethodData);
}

export function formatVolumeByMethod(
    paymentListing: PaymentListing[],
    showPaymentMethod: boolean,
): VolumeByMethod[] {
    const aggregateData = getVolumeByMethod(paymentListing, showPaymentMethod);
    return Object.keys(aggregateData).map((method: string) => {
        const agg = aggregateData[method] as VolumeByMethod;
        agg.amount = aggregateData[method].amount;
        agg.quantity = aggregateData[method].quantity;
        agg.method = PaymentMethod.parse(method);
        return agg;
    });
}

// eslint-disable-next-line
export type VendorPaymentVolumeData = {
    [name: string]: { ACH: number; VIRTUAL_CARD: number; CHECK: number; E_CHECK: number };
};
// eslint-disable-next-line
type VendorPaymentVolume = {
    ACH: number;
    VIRTUAL_CARD: number;
    CHECK: number;
    E_CHECK: number;
    name: string;
    total: number;
};

function getVendorPaymentVolumeData(
    listings: PaymentListing[],
    showPaymentMethod: boolean,
): VendorPaymentVolumeData {
    const vendorPaymentVolumeData = getVendorFields(listings, showPaymentMethod);
    return vendorPaymentVolumeData.reduce((acc, cur) => {
        if (!cur.method) {
            return acc;
        }
        if (acc[cur.name]) {
            acc[cur.name][cur.method] += cur.amount;
        } else {
            // eslint-disable-next-line
            const payeeData = { ACH: 0, VIRTUAL_CARD: 0, E_CHECK: 0, CHECK: 0 };
            payeeData[cur.method] += cur.amount;
            acc[cur.name] = payeeData;
        }
        return acc;
    }, {} as VendorPaymentVolumeData);
}

export function formatVendorPaymentVolumeData(
    listings: PaymentListing[],
    showPaymentMethod: boolean,
): VendorPaymentVolume[] {
    const vendorPaymentVolumeData = getVendorPaymentVolumeData(listings, showPaymentMethod);
    return Object.keys(vendorPaymentVolumeData)
        .map((d: string) => {
            const agg = vendorPaymentVolumeData[d] as VendorPaymentVolume;
            agg.total = agg.ACH + agg.VIRTUAL_CARD + agg.E_CHECK + agg.CHECK;
            agg.name = d;
            return agg;
        })
        .sort((a, b) => b.total - a.total);
}

//
//
//
//
//

export interface TempVendorFields {
    companyName: string;
    contactName: string;
    contactRole: string;
    contactEmail: string;
    companyId: string;
    status: string;
    datePaid: string;
    method: string;
    amountPaid: number;
    paymentStatus: BatchStatusName;
    vendorPaymentId: string;
    payer: string;
}

export type TempVendorData = { [name: string]: TempVendorFields };

function makePlaceholderRole(i: number) {
    return i % 2 ? "[demo]receivables" : i % 3 ? "[demo]payments" : "[demo]settlement";
}

export function getPaymentsByVendorData(batchListings: BatchListing[]): TempVendorFields[] {
    return (
        batchListings
            .flatMap((l) =>
                l.type === "BATCH"
                    ? l.original.payments.map((p, i): TempVendorFields => {
                          return {
                              companyName:
                                  "recipientName" in p
                                      ? p.recipientName // TODO: Inquire about originName vs companyName and change to proper one if needed
                                      : "not available",
                              contactName: "recipientName" in p ? p.recipientName : "not available",
                              contactRole: makePlaceholderRole(i),
                              contactEmail:
                                  "recipientName" in p
                                      ? (makePlaceholderRole(i) + "@" + p.recipientName)
                                            .replace(/['-., ]+/g, "")
                                            .toLowerCase() + ".com"
                                      : "not available",
                              companyId:
                                  "recipientName" in p
                                      ? p.recipientName // TODO: Inquire about originName vs companyName and change to proper one if needed
                                      : "not available",
                              status: "active",
                              datePaid: format(l.created_date, "MM/dd/yyyy"),
                              method: p.metadata.paymentMethod,
                              amountPaid: Number(p.amount),
                              paymentStatus: l.status,
                              vendorPaymentId: calculatePaymentId(p),
                              payer: p.originName,
                          };
                      })
                    : [],
            )
            // next line is for demo purposes and should be based on user's company
            // .filter((payment) => payment.companyName === "ALMA")
            .sort((a, b) => a.companyName.localeCompare(b.companyName))
    );
}

// export function tempFormatTotalPaymentsVolume(listings: PaymentListing[], showPaymentMethod: boolean): number {
//     return formatVolumeByMethod(listings, showPaymentMethod).reduce((acc, cur) => {
//         acc.amount += cur.amount;
//         return acc;
//     }).amount;
// }

// export function getPayeeData(paymentListings: PaymentListing[]) {
//     return paymentListings.map((paymentListing) => {
//         return {
//             method: paymentListing.paymentMethod,
//             amountPaid: paymentListing.amount,
//             payer: paymentListing.data.originName,
//         };
//     });
// }

// export function getPayeeVolumeByMethod(paymentListings: PaymentListing[]) {
//     const payeeData = getPayeeData(paymentListings);
//     return payeeData.reduce((acc, cur) => {
//         if (acc[cur.method]) {
//             acc[cur.method].amount += Number(cur.amountPaid);
//             acc[cur.method].quantity += 1;
//         } else {
//             const payeeData = { amount: 0, quantity: 1 };
//             payeeData.amount += Number(cur.amountPaid);
//             acc[cur.method] = payeeData;
//         }
//         return acc;
//     }, {} as VolumeByMethodData);
// }

// export function formatPayeeVolumeByMethod(payments: PaymentWithDate[]): VolumeByMethod[] {
//     const aggregateData = getPayeeVolumeByMethod(payments);
//     return Object.keys(aggregateData).map((method: string) => {
//         const agg = aggregateData[method] as VolumeByMethod;
//         agg.amount = aggregateData[method].amount;
//         agg.quantity = aggregateData[method].quantity;
//         agg.method = method;
//         return agg;
//     });
// }

// export function getTopPayeePayerStats(payments: PaymentWithDate[]) {
//     const payeeData = getPayeeData(payments);
//     return payeeData.reduce((acc, cur) => {
//         if (acc[cur.payer]) {
//             acc[cur.payer].payer = cur.payer;
//             acc[cur.payer].qty += 1;
//             acc[cur.payer].amountPaid += Number(cur.amountPaid);
//         } else {
//             const payeeDatum = { payer: cur.payer, amountPaid: Number(cur.amountPaid), qty: 1 };
//             acc[cur.payer] = payeeDatum;
//         }
//         return acc;
//     }, {} as { [payer: string]: { payer: string; amountPaid: number; qty: number } });
// }

// export function formatTopPayeePayersByVolume(payments: PaymentWithDate[]) {
//     const topPayeePayers = getTopPayeePayerStats(payments);
//     return Object.keys(topPayeePayers)
//         .map((d: string) => {
//             const agg = topPayeePayers[d];
//             agg.amountPaid = topPayeePayers[d].amountPaid;
//             agg.payer = d;
//             return agg;
//         })
//         .sort((a, b) => b.amountPaid - a.amountPaid);
// }

// export function formatTopPayeePayersByQuantity(payments: PaymentWithDate[]) {
//     const topPayeePayers = getTopPayeePayerStats(payments);
//     return Object.keys(topPayeePayers)
//         .map((d: string) => {
//             const agg = topPayeePayers[d];
//             agg.qty = topPayeePayers[d].qty;
//             agg.payer = d;
//             return agg;
//         })
//         .sort((a, b) => b.qty - a.qty);
// }

// // functions for supplier chart data
// function getPayeeReceivablesData(payments: PaymentWithDate[], showPaymentMethod: boolean): VendorFields[] {
//     return payments.map((payment): VendorFields => {
//         return {
//             date: format(payment.createdDate, "MM/dd/yy"),
//             name: payment.originName,
//             method: (showPaymentMethod ? payment.metadata.paymentMethod : "ACH") as "ACH" | "Card" | "",
//             amount: Number(payment.amount),
//             quantity: 1,
//         };
//     });
// }

// // eslint-disable-next-line
// type SortedPayeePaymentsByMethodData = { [date: string]: { ACH: number; Card: number } };
// // eslint-disable-next-line
// export type SortedPayeePaymentsByMethod = { ACH: number; Card: number; date: string; total: number };

// function getSortedPayeePaymentsData(
//     payments: PaymentWithDate[],
//     showPaymentMethod: boolean,
// ): SortedPaymentsByMethodData {
//     const sortedPaymentsData = getPayeeReceivablesData(payments, showPaymentMethod);
//     return sortedPaymentsData.reduce((acc, cur) => {
//         if (cur.method === "") {
//             return acc;
//         }
//         if (acc[cur.date]) {
//             acc[cur.date][cur.method] += cur.amount;
//         } else {
//             // eslint-disable-next-line
//             const payeeData = { ACH: 0, Card: 0, eCheck: 0, Check: 0 };
//             payeeData[cur.method] += cur.amount;
//             acc[cur.date] = payeeData;
//         }
//         return acc;
//     }, {} as SortedPaymentsByMethodData);
// }

// export function formatSortedPayeePaymentsByMethod(
//     payments: PaymentWithDate[],
//     showPaymentMethod: boolean,
// ): SortedPaymentsByMethod[] {
//     const sortedPaymentData = getSortedPayeePaymentsData(payments, showPaymentMethod);
//     return Object.keys(sortedPaymentData).map((date: string) => {
//         const agg = sortedPaymentData[date] as SortedPaymentsByMethod;
//         agg.total = agg.ACH + agg.Card;
//         agg.date = date;
//         return agg;
//     });
// }
