import { CreditCardIcon, CurrencyDollarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { selectSortedListings } from "../store/listings";
import { formatFromCents, humanReadablePaymentMethods, tempFormatUuidString } from "../utils";
import { classNames } from "../utils/classNames";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { useAppSelector } from "../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { getStatusCssClassnames } from "../utils/statusStyles";
import { LifeCycleFeed } from "./LifeCycleFeed";
import { dismissDetailPane, showDetailPane } from "./DetailPane";
import {
    BatchEvent,
    BatchStatus,
    BatchStatusName,
    BatchStatusSequence,
    PaymentMethod,
} from "@corechain-technologies/types";
import { PaymentListing, selectPayments } from "../store/payments";
import { EnvelopeIcon, WindowIcon } from "@heroicons/react/20/solid";

const BatchDetail: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useI18n(book);

    const paymentsArray = useAppSelector(selectPayments).filter((payment) => {
        if (!location.hash.includes(encodeURI(payment.batchId))) {
            console.log(location.hash, payment, encodeURI(payment.batchId));
        }
        return location.hash.includes(encodeURI(payment.batchId));
    });

    if (paymentsArray.length === 0) {
        return <></>;
    }

    const totalAmount = paymentsArray.reduce((acc, payment) => acc + Number(payment.amount), 0);
    const singlePayment = paymentsArray[0];

    function makeBatchEvents(payment: PaymentListing) {
        return BatchStatusSequence.reduce(
            (acc, cur) => {
                switch (cur) {
                    case BatchStatusName.CREATED:
                        return payment.createdDate
                            ? acc.concat({
                                  status: BatchStatusName.CREATED,
                                  timestamp: payment.createdDate,
                              })
                            : acc;
                    case BatchStatusName.APPROVED:
                        return payment.approvedDate
                            ? acc.concat({
                                  status: BatchStatusName.APPROVED,
                                  timestamp: payment.approvedDate,
                              })
                            : acc;
                    case BatchStatusName.SCHEDULED:
                        return payment.scheduledDate
                            ? acc.concat({
                                  status: BatchStatusName.SCHEDULED,
                                  timestamp: payment.scheduledDate,
                              })
                            : acc;
                    case BatchStatusName.DELIVERED:
                        return payment.deliveredDate
                            ? acc.concat({
                                  status: BatchStatusName.DELIVERED,
                                  timestamp: payment.deliveredDate,
                              })
                            : acc;
                    case BatchStatusName.ACCEPTED:
                        return payment.acceptedDate
                            ? acc.concat({
                                  status: BatchStatusName.ACCEPTED,
                                  timestamp: payment.acceptedDate,
                              })
                            : acc;
                    case BatchStatusName.SETTLED:
                        return payment.settledDate
                            ? acc.concat({
                                  status: BatchStatusName.SETTLED,
                                  timestamp: payment.settledDate,
                              })
                            : acc;
                    case BatchStatusName.REJECTED:
                        return payment.rejectedDate
                            ? acc.concat({
                                  status: BatchStatusName.REJECTED,
                                  timestamp: payment.rejectedDate,
                              })
                            : acc;
                    case BatchStatusName.REVERTED:
                        return payment.lastStatus === BatchStatus.REVERTED
                            ? acc.concat({
                                  status: BatchStatusName.REVERTED,
                                  timestamp: payment.settledDate ?? new Date(),
                              })
                            : acc;
                }
            },
            [] as Omit<BatchEvent, "routingDecisions">[],
        );
    }

    return (
        <>
            <div className="px-4 sm:px-6">
                <div className="flex items-start justify-between">
                    <div className="text-lg font-medium text-gray-900 truncate">
                        {singlePayment.batchId}
                        <div className="flex items-center justify-left gap-2 pt-2">
                            <div
                                className={classNames(
                                    getStatusCssClassnames(singlePayment.lastStatus ?? BatchStatusName.ERROR),
                                    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                                )}
                            >
                                <span className="capitalize">{singlePayment?.lastStatus?.toLowerCase()}</span>
                            </div>
                        </div>
                    </div>

                    <div className="ml-3 flex h-7 items-center">
                        <button
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => {
                                navigate(dismissDetailPane());
                            }}
                        >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative bg-gray-200 mt-4 flex-1 px-4 sm:px-6 overflow-scroll">
                <div className="absolute inset-0 pt-5 pb-6 px-4 sm:px-6">
                    <div
                        className="h-full border overflow-y-scroll bg-white shadow border-gray-200 sm:rounded-lg"
                        aria-hidden="true"
                    >
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("batchDetail/originatingAccount")}{" "}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {singlePayment.payerId}
                                    </dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("batchDetail/totalAmount")}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {formatFromCents(totalAmount.toString() ?? "")}
                                    </dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("batchDetail/numberPayments")}{" "}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {paymentsArray.length.toString() ?? ""}
                                    </dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("paymentDetail/originalBatchId")}{" "}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {tempFormatUuidString(singlePayment.batchId)}
                                    </dd>
                                </div>
                                <LifeCycleFeed events={makeBatchEvents(singlePayment)} />
                                <div className="py-4 sm:py-5 sm:px-6">
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                                            {paymentsArray.map((payment, i) => {
                                                return (
                                                    <li
                                                        key={payment.correlationId}
                                                        className="group flex items-center justify-between py-3 pl-3 pr-4 text-sm bg-white hover:bg-gray-50 cursor-pointer"
                                                        onClick={() => {
                                                            navigate(
                                                                showDetailPane(
                                                                    "#detail/payment/",
                                                                    payment.paymentId,
                                                                ),
                                                            );
                                                        }}
                                                    >
                                                        <div className="flex w-0 flex-1 items-center">
                                                            <span className="w-0 flex-1 capitalize truncate">
                                                                {payment.payee.toLowerCase()}
                                                            </span>
                                                        </div>
                                                        <div className="flex w-0 flex-1 items-end">
                                                            <span className="ml-2 w-0 flex-1 truncate">
                                                                {formatFromCents(Number(payment.amount))}
                                                            </span>
                                                        </div>
                                                        <div className="flex justify-left gap-1">
                                                            <div className="flex justify-center">
                                                                {payment.paymentMethod ===
                                                                    PaymentMethod.ACH && (
                                                                    <CurrencyDollarIcon
                                                                        className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                                {payment.paymentMethod ===
                                                                    PaymentMethod.VIRTUAL_CARD && (
                                                                    <CreditCardIcon
                                                                        className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                                {payment.paymentMethod ===
                                                                    PaymentMethod.E_CHECK && (
                                                                    <WindowIcon
                                                                        className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                                {payment.paymentMethod ===
                                                                    PaymentMethod.CHECK && (
                                                                    <EnvelopeIcon
                                                                        className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="ml-1 flex-shrink-0">
                                                                {humanReadablePaymentMethods(
                                                                    payment.paymentMethod ?? "",
                                                                )}
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BatchDetail;
