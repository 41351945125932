/* eslint-disable @typescript-eslint/no-use-before-define */
import "@redux-devtools/extension";

import { Action, applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from "redux";
import { servicesReducer } from "./services";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { userReducer } from "./user";
import { batchesReducer } from "./batches";
import { receiptsReducer } from "./receipts";
import { listingsReducer } from "./listings";
import { featuresReducer, initFeatureEnv } from "../store/features";
import { fileUploadReducer } from "./uploads";
import { notificationsReducer } from "./notifications";
import { statisticsReducer } from "./statistics";
import { supplierOnboardingReducer } from "./supplierOnboarding";
import { supplierCompanyDataReducer } from "./supplierCompanyData";
import { payerReducer } from "./payer";
import { hasPayer, Role as UserRole } from "@corechain-technologies/types";
import { createSelector } from "reselect";
import "./payments.ts";
import { paymentsReducer } from "./payments";

const rootReducer = combineReducers({
    batches: batchesReducer,
    features: featuresReducer,
    files: fileUploadReducer,
    listings: listingsReducer,
    notifications: notificationsReducer,
    payer: payerReducer,
    payments: paymentsReducer,
    receipts: receiptsReducer,
    services: servicesReducer,
    statistics: statisticsReducer,
    supplierCompanyData: supplierCompanyDataReducer,
    supplierOnboarding: supplierOnboardingReducer,
    user: userReducer,
});

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
        // FFS: https://github.com/reduxjs/redux-devtools/blob/main/extension/docs/API/Arguments.md
        serialize: {
            replacer: (key: string, value: unknown) => {
                if (typeof value === "bigint") {
                    return "bigint:" + value.toString() + ":bigint";
                }
                return value;
            },
            reviver: (key: string, value: unknown) => {
                if (typeof value === "string" && value.startsWith("bigint:") && value.endsWith(":bigint")) {
                    return BigInt(value.slice(7, -7));
                }
                return value;
            },
        },
    }) || compose;

// const fetchInitialPaymentData: Middleware = (storeApi) => (next) => (action) => {
//     if (action.type !== "USER:RESTORE_SESSION") {
//         return next(action);
//     }
//     next(action);
//     const state = storeApi.getState();
//     const payer = state.payer;
//     const cxUser = state.user.cxUser;
//     const userIsBuyer = cxUser?.roles.includes(UserRole.BUYER); //TODO: actually check for permissions from API
//     const userIsVendor = cxUser?.roles.includes(UserRole.SUPPLIER);
//     const batchStatus = state.batches.status;
//     const receiptStatus = state.receipts.status;
//     if (payer && userIsBuyer && batchStatus === "UNINITIALIZED") {
//         dispatch(fetchBatches());
//         dispatch(initStatistics(payer.id));
//     }
//     if (userIsVendor && receiptStatus === "UNINITIALIZED") {
//         dispatch(fetchReceipts());
//     }
// };

export const createAppStore = () =>
    createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware<ThunkDispatch<RootState, unknown, Action>>(thunk),
            // applyMiddleware<ThunkDispatch<RootState, unknown, Action>>(thunk, fetchInitialPaymentData),
        ),
    );

const store = createAppStore();

const dispatch = store.dispatch;
export type AppDispatch = typeof dispatch;

dispatch(initFeatureEnv(import.meta.env ?? {}));

export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export type RootState = ReturnType<typeof rootReducer>;

export const selectActivePayer = createSelector(
    (state: Pick<RootState, "payer" | "user">) => ({ payer: state.payer, user: state.user.cxUser }),
    ({ payer, user }) => {
        if (payer && user && hasPayer(user, payer.id)) {
            return payer;
        } else if (user && user.payers[0]) {
            return user.payers[0];
        }

        return null;
    },
);

export default store as typeof store;
