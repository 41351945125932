import { NachaPaymentInstructionMessage } from "@corechain-technologies/types";
import { BatchListing, PaymentWithDate } from "../store/listings";
import { Statistics } from "../store/statistics";
import { uniq } from "fp-ts/Array";
import { string } from "fp-ts";
import { formatFromCents, humanReadablePaymentMethods } from "./helpers";
import { format } from "date-fns";
import { PaymentListing } from "../store/payments";
import currency from "currency.js";

interface DateAggregateRow {
    date: string;
    totalAmount: string;
    numberOfPayments: number;
    numberOfAchPayments: number;
    totalAchAmount: string;
    numberOfCardPayments: number;
    totalCardAmount: string;
}

interface VendorAggregateRow {
    vendorName: string;
    numberOfPayments: number;
    totalAmount: string;
}

interface PayerAggregateRow {
    payerName: string;
    numberOfPayments: number;
    totalAmount: string;
}

interface LifetimeTotal {
    totalAmount: string;
    numberOfPayments: number;
    numberOfVendorsPaid: number;
    numberOfCardPayments: number;
    totalCardAmount: string;
    numberOfAchPayments: number;
    totalAchAmount: string;
}

interface PayeeDateAggregateRow {
    date: string;
    totalAmount: string;
    numberOfPayments: number;
    numberOfAchPayments: number;
    totalAchAmount: string;
    numberOfCardPayments: number;
    totalCardAmount: string;
}

interface PayeeLifetimeTotal {
    totalAmount: string;
    numberOfPayments: number;
    numberOfPayers: number;
    numberOfCardPayments: number;
    totalCardAmount: string;
    numberOfAchPayments: number;
    totalAchAmount: string;
}

interface ReportingData {
    dateAggregate: DateAggregateRow[];
    vendorAggregate: VendorAggregateRow[];
    lifetimeTotal: LifetimeTotal;
}

interface PayeeReportingData {
    receivablesDateAggregate: PayeeDateAggregateRow[];
    payerAggregate: PayerAggregateRow[];
    payeeLifetimeTotal: PayeeLifetimeTotal;
}

export function camelCaseToWords(s: string) {
    const result = s.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export function csvHelper(data: {}[]) {
    if (!data[0] || !data) return "";
    const keys = Object.keys(data[0]);
    keys.splice(1, 0, keys.splice(4, 1)[0]);
    console.log("keys", keys);

    const rows = data.map((row) => {
        const line: any[] = [];
        keys.forEach((k) => {
            line.push(row[k as keyof typeof row]);
        });
        return line.join('","');
    });
    const report = rows.join('"\n"');
    const columnHeader = keys.map((k) => camelCaseToWords(k));
    return '"' + columnHeader.join('","') + '"\n"' + report + '"';
}

export function reporting(batchListings: BatchListing[], stats: Statistics): ReportingData {
    const batches = batchListings.map((listing) => listing.original);
    const paymentsAsAny = batches.flatMap((batch) => batch.payments as any);

    const payments = batches
        .flatMap((batch) => batch.payments)
        .filter((a): a is NachaPaymentInstructionMessage => "recipientName" in a);

    const cardPayments = paymentsAsAny.filter((payment) => payment.method === "Card");
    const achPayments = paymentsAsAny.filter((payment) => payment.method === "ACH");
    const vendors = payments.map((payment) => payment.recipientName);
    const uniqueVendors = uniq(string.Eq)(vendors);

    const initialValue = 0;
    const cardTotal = cardPayments
        .map((payment) => Number(payment.amount))
        .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
    const achTotal = achPayments
        .map((payment) => Number(payment.amount))
        .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

    const vendorAggregate = uniqueVendors.map((vendor) => {
        const paymentsForThisVendor = payments.filter((payment) => payment.recipientName === vendor);
        const totalAmountThisVendor = paymentsForThisVendor
            .map((payment) => Number(payment.amount))
            .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

        return {
            vendorName: vendor,
            numberOfPayments: paymentsForThisVendor.length,
            totalAmount: formatFromCents(totalAmountThisVendor),
        };
    });

    const dates = batches.map((batch) => format(batch.events[0].timestamp, "MM/dd/yyyy"));
    const uniqueDates = uniq(string.Eq)(dates);

    const dateAggregate = uniqueDates
        .map((date) => {
            const batchesOnThisDate = batches.filter(
                (batch) => format(batch.events[0].timestamp, "MM/dd/yyyy") === date,
            );
            const totalAmount = batchesOnThisDate
                .map((batch) => Number(batch.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            const paymentsOnThisDate = batchesOnThisDate.flatMap((batch) => batch.payments as any);

            const cardPayments = paymentsOnThisDate.filter((payment) => payment.method === "Card");
            const achPayments = paymentsOnThisDate.filter((payment) => payment.method === "ACH");

            const cardTotal = cardPayments
                .map((payment) => Number(payment.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            const achTotal = achPayments
                .map((payment) => Number(payment.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            return {
                date,
                totalAmount: formatFromCents(totalAmount),
                numberOfPayments: paymentsOnThisDate.length,
                numberOfAchPayments: achPayments.length,
                totalAchAmount: formatFromCents(achTotal),
                numberOfCardPayments: cardPayments.length,
                totalCardAmount: formatFromCents(cardTotal),
            };
        })
        .sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)));

    return {
        dateAggregate,
        vendorAggregate,
        lifetimeTotal: {
            totalAmount: formatFromCents(stats.totalCentsAmount),
            numberOfPayments: stats.totalTransactionCount,
            numberOfVendorsPaid: uniqueVendors.length,
            numberOfCardPayments: cardPayments.length,
            totalCardAmount: formatFromCents(cardTotal),
            numberOfAchPayments: achPayments.length,
            totalAchAmount: formatFromCents(achTotal),
        },
    };
}

const directory = {
    dateAggregate: "date_agg.csv",
    receivablesDateAggregate: "date_agg_received",
    vendorAggregate: "vendor_agg.csv",
    lifetimeTotal: "totals.csv",
    payeeLifetimeTotal: "receivables_totals.csv",
    pendingPayments: "in_progress.csv",
    pendingReceivables: "receivables_in_progress.csv",
    vendorsByVolume: "vendors_by_volume.csv",
    vendorsByCount: "vendors_by_payment_count.csv",
    payersByVolume: "payers_by_volume.csv",
    payersByCount: "payers_by_payment_count.csv",
};

export function downloadVendorTimeReport(batchListings: BatchListing[], stats: Statistics) {
    const file = new File(
        [
            csvHelper(
                reporting(batchListings, stats).vendorAggregate.sort((a, b) =>
                    a.vendorName.localeCompare(b.vendorName),
                ),
            ),
        ],
        directory.vendorAggregate,
        {
            type: "text/csv",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function downloadDateReport(batchListings: BatchListing[], stats: Statistics) {
    const file = new File(
        [csvHelper(reporting(batchListings, stats).dateAggregate)],
        directory.dateAggregate,
        {
            type: "text/plain",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function downloadLifetimeReport(batchListings: BatchListing[], stats: Statistics) {
    const file = new File(
        [csvHelper([reporting(batchListings, stats).lifetimeTotal])],
        directory.lifetimeTotal,
        {
            type: "text/plain",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function tempDownloadPaymentsPending(batchListings: BatchListing[], stats: Statistics) {
    const pendingBatchListings = batchListings.filter(
        (b) => !["settled", "rejected"].includes(b.status.toLowerCase()),
    );

    const pendingBatchData = pendingBatchListings
        .map((pendingBatch) => {
            const date = format(pendingBatch.original.events[0].timestamp, "MM/dd/yyyy");
            const pendingPayments = pendingBatch.original.payments as readonly any[];
            const cardPayments = pendingPayments.filter((payment) => payment.method === ("Card" as any));
            const achPayments = pendingPayments.filter((payment) => payment.method === "ACH");

            const initialValue = 0;
            const cardTotal = cardPayments
                .map((payment) => Number(payment.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            const achTotal = achPayments
                .map((payment) => Number(payment.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            return {
                date: date,
                totalAmount: formatFromCents(pendingBatch.amount.toString()),
                numberOfPayments: pendingBatch.original.numberOfPayments,
                numberOfAchPayments: achPayments.length,
                totalAchAmount: formatFromCents(achTotal),
                numberOfCardPayments: cardPayments.length,
                totalCardAmount: formatFromCents(cardTotal),
            };
        })
        .sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)));

    const file = new File([csvHelper(pendingBatchData)], directory.pendingPayments, {
        type: "text/csv",
    });

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function tempDownloadVendorPaymentVolume(batchListings: BatchListing[], stats: Statistics) {
    const file = new File(
        [
            csvHelper(
                reporting(batchListings, stats).vendorAggregate.sort(
                    (a, b) => b.numberOfPayments - a.numberOfPayments,
                ),
            ),
        ],
        directory.vendorsByVolume,
        {
            type: "text/csv",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function tempDownloadVendorPaymentCount(batchListings: BatchListing[], stats: Statistics) {
    const file = new File(
        [
            csvHelper(
                reporting(batchListings, stats).vendorAggregate.sort(
                    (a, b) => b.numberOfPayments - a.numberOfPayments,
                ),
            ),
        ],
        directory.vendorsByCount,
        {
            type: "text/csv",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

// supplier insight reporting
export function payeeReporting(payments: PaymentWithDate[], stats: Statistics): PayeeReportingData {
    const cardPayments = payments.filter((payment) => payment.metadata.paymentMethod === "Card");
    const achPayments = payments.filter((payment) => payment.metadata.paymentMethod === "ACH");
    const payers = payments.map((payment) => payment.originName);
    const uniquePayers = uniq(string.Eq)(payers);

    const initialValue = 0;
    const cardTotal = cardPayments
        .map((payment) => Number(payment.amount))
        .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
    const achTotal = achPayments
        .map((payment) => Number(payment.amount))
        .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

    const payerAggregate = uniquePayers.map((payer) => {
        const paymentsFromThisPayer = payments.filter((payment) => payment.originName === payer);
        const totalAmountThisPayer = paymentsFromThisPayer
            .map((payment) => Number(payment.amount))
            .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

        return {
            payerName: payer,
            numberOfPayments: paymentsFromThisPayer.length,
            totalAmount: formatFromCents(totalAmountThisPayer),
        };
    });
    const dates = payments.map((payment) => format(payment.events[0].timestamp, "MM/dd/yyyy"));
    const uniqueDates = uniq(string.Eq)(dates);

    const receivablesDateAggregate = uniqueDates
        .map((date) => {
            const paymentsOnThisDate = payments.filter(
                (payment) => format(payment.events[0].timestamp, "MM/dd/yyyy") === date,
            );
            const totalAmount = paymentsOnThisDate
                .map((batch) => Number(batch.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            const cardPayments = paymentsOnThisDate.filter(
                (payment) => payment.metadata.paymentMethod === "Card",
            );
            const achPayments = paymentsOnThisDate.filter(
                (payment) => payment.metadata.paymentMethod === "ACH",
            );

            const cardTotal = cardPayments
                .map((payment) => Number(payment.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            const achTotal = achPayments
                .map((payment) => Number(payment.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            return {
                date,
                totalAmount: formatFromCents(totalAmount),
                numberOfPayments: paymentsOnThisDate.length,
                numberOfAchPayments: achPayments.length,
                totalAchAmount: formatFromCents(achTotal),
                numberOfCardPayments: cardPayments.length,
                totalCardAmount: formatFromCents(cardTotal),
            };
        })
        .sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)));

    return {
        receivablesDateAggregate,
        payerAggregate,
        payeeLifetimeTotal: {
            totalAmount: formatFromCents(cardTotal + achTotal),
            numberOfPayments: payments.length,
            numberOfPayers: uniquePayers.length,
            numberOfCardPayments: cardPayments.length,
            totalCardAmount: formatFromCents(cardTotal),
            numberOfAchPayments: achPayments.length,
            totalAchAmount: formatFromCents(achTotal),
        },
    };
}

export function lifetimePayeeReporting(batchListings: BatchListing[], stats: Statistics) {
    const payments: PaymentWithDate[] = batchListings.flatMap((batch) =>
        batch.original.payments
            .map(
                (payment) =>
                    ({
                        ...payment,
                        createdDate: batch.created_date,
                        currentStatus: batch.original.events[batch.original.events.length - 1].status,
                        events: batch.original.events,
                    }) as PaymentWithDate,
            )
            .filter((payment) => payment.recipientName === "ALMA"),
    );
    const cardPayments = payments.filter((payment) => payment.metadata.paymentMethod === "Card");
    console.log(cardPayments);
    const achPayments = payments.filter((payment) => payment.metadata.paymentMethod === "ACH");
    const payers = payments.map((payment) => payment.originName);
    const uniquePayers = uniq(string.Eq)(payers);

    const initialValue = 0;
    const cardTotal = cardPayments
        .map((payment) => Number(payment.amount))
        .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
    const achTotal = achPayments
        .map((payment) => Number(payment.amount))
        .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

    return {
        lifetimeTotal: {
            totalAmount: formatFromCents(cardTotal + achTotal),
            numberOfPayments: payments.length,
            numberOfVendorsPaid: uniquePayers.length,
            numberOfCardPayments: cardPayments.length,
            totalCardAmount: formatFromCents(cardTotal),
            numberOfAchPayments: achPayments.length,
            totalAchAmount: formatFromCents(achTotal),
        },
    };
}

export function downloadPayeeDateReport(receivables: PaymentWithDate[], stats: Statistics) {
    const file = new File(
        [csvHelper(payeeReporting(receivables, stats).receivablesDateAggregate)],
        directory.receivablesDateAggregate,
        {
            type: "text/plain",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function downloadPayeeChartDateReport(dataSource: PaymentWithDate[], stats: Statistics) {
    downloadPayeeDateReport(dataSource, stats);
}

export function downloadPayerChartDateReport(dataSource: BatchListing[], stats: Statistics) {
    downloadDateReport(dataSource, stats);
}

export function downloadLifetimeReceivedReport(batchListings: BatchListing[], stats: Statistics) {
    const file = new File(
        [csvHelper([lifetimePayeeReporting(batchListings, stats).lifetimeTotal])],
        directory.receivablesDateAggregate,
        {
            type: "text/plain",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function downloadPayeePendingPayments(batchListings: BatchListing[], stats: Statistics) {
    const pendingBatchListings = batchListings.filter(
        (b) => !["settled", "rejected"].includes(b.status.toLowerCase()),
    );

    const pendingBatchData = pendingBatchListings
        .map((pendingBatch) => {
            const date = format(pendingBatch.original.events[0].timestamp, "MM/dd/yyyy");
            const pendingPayments = pendingBatch.original.payments as readonly any[];
            const cardPayments = pendingPayments.filter((payment) => payment.method === ("Card" as any));
            const achPayments = pendingPayments.filter((payment) => payment.method === "ACH");

            const initialValue = 0;
            const cardTotal = cardPayments
                .map((payment) => Number(payment.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            const achTotal = achPayments
                .map((payment) => Number(payment.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            return {
                date: date,
                totalAmount: formatFromCents(pendingBatch.amount.toString()),
                numberOfPayments: pendingBatch.original.numberOfPayments,
                numberOfAchPayments: achPayments.length,
                totalAchAmount: formatFromCents(achTotal),
                numberOfCardPayments: cardPayments.length,
                totalCardAmount: formatFromCents(cardTotal),
            };
        })
        .sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)));

    const file = new File([csvHelper(pendingBatchData)], directory.pendingPayments, {
        type: "text/csv",
    });

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function downloadPayeeReceivableVolume(batchListings: BatchListing[], stats: Statistics) {
    const file = new File(
        [
            csvHelper(
                reporting(batchListings, stats).vendorAggregate.sort(
                    (a, b) => b.numberOfPayments - a.numberOfPayments,
                ),
            ),
        ],
        directory.vendorsByVolume,
        {
            type: "text/csv",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function downloadPayeeReceivableCount(batchListings: BatchListing[], stats: Statistics) {
    const file = new File(
        [
            csvHelper(
                reporting(batchListings, stats).vendorAggregate.sort(
                    (a, b) => b.numberOfPayments - a.numberOfPayments,
                ),
            ),
        ],
        directory.vendorsByCount,
        {
            type: "text/csv",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function tempDownloadReceivablesPending(batchListings: BatchListing[], stats: Statistics) {
    const pendingBatchListings = batchListings.filter(
        (b) => !["settled", "rejected"].includes(b.status.toLowerCase()),
    );
    const pendingReceivables = batchListings.flatMap((batch) =>
        batch.original.payments.filter((payment) => payment.recipientName === "ALMA"),
    );
    // .filter((batch) => batch.length > 0);

    const pendingReceivablesData = pendingReceivables
        .map((receivable) => {
            const date = format(pendingBatchListings[0].original.events[0].timestamp, "MM/dd/yyyy");
            const pendingPayments = pendingReceivables as readonly any[];
            const cardPayments = pendingPayments.filter((payment) => payment.method === ("Card" as any));
            const achPayments = pendingPayments.filter((payment) => payment.method === "ACH");

            const initialValue = 0;
            const cardTotal = cardPayments
                .map((payment) => Number(payment.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            const achTotal = achPayments
                .map((payment) => Number(payment.amount))
                .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);

            return {
                date: date,
                totalAmount: formatFromCents(receivable.amount.toString()),
                // numberOfPayments: pendingReceivables.length,
                // numberOfAchPayments: achPayments.length,
                // totalAchAmount: formatFromCents(achTotal),
                // numberOfCardPayments: cardPayments.length,
                // totalCardAmount: formatFromCents(cardTotal),
            };
        })
        .sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)));

    const file = new File([csvHelper(pendingReceivablesData)], directory.pendingReceivables, {
        type: "text/csv",
    });

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function downloadPayeeLifetimeReport(batchListings: BatchListing[], stats: Statistics) {
    const payments: PaymentWithDate[] = batchListings.flatMap((batch) =>
        batch.original.payments
            .map(
                (payment) =>
                    ({
                        ...payment,
                        createdDate: batch.created_date,
                        currentStatus: batch.original.events[batch.original.events.length - 1].status,
                        events: batch.original.events,
                    }) as PaymentWithDate,
            )
            .filter((payment) => payment.recipientName === "ALMA"),
    );
    const file = new File(
        [csvHelper([payeeReporting(payments, stats).payeeLifetimeTotal])],
        directory.payeeLifetimeTotal,
        {
            type: "text/plain",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function downloadPayeePaymentVolume(batchListings: BatchListing[], stats: Statistics) {
    const payments: PaymentWithDate[] = batchListings.flatMap((batch) =>
        batch.original.payments
            .map(
                (payment) =>
                    ({
                        ...payment,
                        createdDate: batch.created_date,
                        currentStatus: batch.original.events[batch.original.events.length - 1].status,
                        events: batch.original.events,
                    }) as PaymentWithDate,
            )
            .filter((payment) => payment.recipientName === "ALMA"),
    );
    const file = new File(
        [
            csvHelper(
                payeeReporting(payments, stats).payerAggregate.sort(
                    (a, b) => b.numberOfPayments - a.numberOfPayments,
                ),
            ),
        ],
        directory.payersByVolume,
        {
            type: "text/csv",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function downloadPayeePaymentCount(batchListings: BatchListing[], stats: Statistics) {
    const payments: PaymentWithDate[] = batchListings.flatMap((batch) =>
        batch.original.payments
            .map(
                (payment) =>
                    ({
                        ...payment,
                        createdDate: batch.created_date,
                        currentStatus: batch.original.events[batch.original.events.length - 1].status,
                        events: batch.original.events,
                    }) as PaymentWithDate,
            )
            .filter((payment) => payment.recipientName === "ALMA"),
    );
    const file = new File(
        [
            csvHelper(
                payeeReporting(payments, stats).payerAggregate.sort(
                    (a, b) => b.numberOfPayments - a.numberOfPayments,
                ),
            ),
        ],
        directory.payersByCount,
        {
            type: "text/csv",
        },
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

function uuidRemover(input: string): string {
    // made to accomodate a specific user request to trim data in a particular field
    const splitArray = input.split(" ");
    if (splitArray.length > 1) {
        if (splitArray[0].length > 31) {
            splitArray.shift();
            return splitArray.join(" ");
        } else {
            return input;
        }
    } else return input;
}

export function downloadFilteredPaymentsReport(payments: PaymentListing[]) {
    const paymentsForCsv: {}[] = payments.map((payment: PaymentListing) => {
        const temp = {
            ...payment,
            totalAmount: currency(Number(payment.amount), { fromCents: true }).format(),
            payeeId: uuidRemover(payment.payeeId ?? ""),
            paymentMethod: humanReadablePaymentMethods(payment.paymentMethod ?? ""),
        };

        delete (temp as any).data;
        delete (temp as any).payerId;
        delete (temp as any).numberOfPayments;
        delete (temp as any).createdBy;
        delete (temp as any).scheduledDeliveryDate;
        delete (temp as any).uploadedDate;
        delete (temp as any).approvedDate;
        delete (temp as any).acceptedDate;
        delete (temp as any).deliveredDate;
        delete (temp as any).rejectedDate;
        delete (temp as any).scheduledDate;
        delete (temp as any).acceptedDate;
        delete (temp as any).correlationId;
        delete (temp as any).amount;
        delete (temp as any).erroredDate;
        return temp;
    });
    const file = new File([csvHelper(paymentsForCsv)], "Payments Report", {
        type: "text/csv",
    });

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}
