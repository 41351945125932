import { BatchStatusName, PaymentMethod, calculatePaymentId } from "@corechain-technologies/types";
import { useNavigate } from "react-router-dom";
import { showDetailPane } from "./DetailPane";
import { CurrencyDollarIcon, CreditCardIcon, WindowIcon, EnvelopeIcon } from "@heroicons/react/20/solid";
import { formatFromCents } from "../utils";
import { getStatusCssClassnames, lowercase } from "../utils/statusStyles";
import { classNames } from "../utils/classNames";
import { format } from "date-fns";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { PaymentListing } from "../store/payments";

export interface PaymentListingProps {
    payment: PaymentListing;
}

const PaymentListingDesktop: React.FC<PaymentListingProps> = ({ payment }) => {
    const { t } = useI18n(book);
    const navigate = useNavigate();
    const currentStatus = payment.lastStatus;

    return (
        <tr
            key={payment.correlationId}
            className="group bg-white hover:bg-gray-50 hover:cursor-pointer card"
            onClick={() =>
                navigate(
                    showDetailPane(
                        "#detail/payment/",
                        calculatePaymentId({
                            batchId: payment.batchId,
                            payerId: payment.data.payerId,
                            sourceLineNumber: payment.data.sourceLineNumber,
                        }),
                    ),
                )
            }
        >
            <td className="pl-4 py-4 text-left whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                {payment.createdDate ? (
                    <time dateTime={format(payment.createdDate, "MM/dd/yyyy")}>
                        {format(payment.createdDate, "MM/dd/yyyy")}
                    </time>
                ) : (
                    <></>
                )}
            </td>
            <td className="py-4 whitespace-nowrap text-sm text-gray-800 border-b border-gray-200 border-spacing-0">
                <div className="flex">
                    <div className="text-sm">
                        <p className="text-gray-500 truncate max-w-xxs">{payment.data.recipientName}</p>
                    </div>
                </div>
            </td>
            <td className="py-4 text-right font-medium whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                <span>$</span>
                <span className="text-gray-800">{formatFromCents(Number(payment.amount))}</span>{" "}
                {t("currency")}
            </td>
            <td className="px-6 py-4 text-center hidden md:table-cell print:table-cell whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                <div className="flex gap-1">
                    {payment.paymentMethod === PaymentMethod.ACH && (
                        <CurrencyDollarIcon
                            className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                        />
                    )}
                    {payment.paymentMethod === PaymentMethod.VIRTUAL_CARD && (
                        <CreditCardIcon
                            className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                        />
                    )}
                    {payment.paymentMethod === PaymentMethod.E_CHECK && (
                        <WindowIcon
                            className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                        />
                    )}
                    {payment.paymentMethod === PaymentMethod.CHECK && (
                        <EnvelopeIcon
                            className="flex-shrink-0 h-5 w-5 mr-0.5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                        />
                    )}
                    <span className="text-gray-500">{payment?.data?.metadata?.paymentMethod}</span>
                </div>
            </td>
            <td className="hidden md:table-cell pl-4 py-4 whitespace-nowrap text-sm text-gray-500  border-b border-gray-200 border-spacing-0 print:table-cell">
                <span
                    className={classNames(
                        getStatusCssClassnames(currentStatus as BatchStatusName),
                        "inline-flex -ml-1.5 px-2.5 py-0.5 rounded-full text-xs font-medium capitalize",
                    )}
                >
                    <span className="capitalize">{lowercase(currentStatus ?? "")}</span>
                </span>
            </td>
            <td className="pr-4 py-4 text-right whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 border-spacing-0">
                {payment.lastStatusDate ? (
                    <time dateTime={format(payment.lastStatusDate, "MM/dd/yyyy")}>
                        {format(payment.lastStatusDate, "MM/dd/yyyy")}
                    </time>
                ) : (
                    <></>
                )}
            </td>
        </tr>
    );
};

export default PaymentListingDesktop;
