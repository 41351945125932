import { BatchStatusName } from "@corechain-technologies/types";

export const statusCssClassnames = {
    success: "bg-green-200 text-green-800",
    processing: "bg-yellow-100 text-yellow-800",
    failed: "bg-red-200 text-red-800",
    rejected: "bg-gray-100 text-gray-700",
} as const;

export type StatusStylesName = keyof typeof statusCssClassnames;

export type LifecycleStatus = Lowercase<BatchStatusName>;

export type LifecycleStatusSettled = Lowercase<BatchStatusName.SETTLED>;
export type LifecycleStatusError = Lowercase<BatchStatusName.ERROR>;

export type LifecycleStatusTerminal = LifecycleStatusSettled | LifecycleStatusError;
export type LifecycleStatusPending = Exclude<LifecycleStatus, LifecycleStatusTerminal>;

export function lowercase<A extends string>(input: A): Lowercase<A> {
    return input.toLowerCase() as any;
}

export function isLifecycleStatus<S extends BatchStatusName>(status: S, input: string): input is S {
    return lowercase(input) === lowercase(status);
}

export function isLifecycleStatusTerminal(input: string): input is LifecycleStatusTerminal {
    return Object.keys(BatchStatusName)
        .filter(
            (status): status is string =>
                isLifecycleStatus(BatchStatusName.SETTLED, status) ||
                isLifecycleStatus(BatchStatusName.ERROR, status),
        )
        .includes(input);
}

export function isLifecycleStatusOk(input: string): input is LifecycleStatusPending {
    return !isLifecycleStatus(BatchStatusName.ERROR, input);
}

export function getStatusCssClassnames(
    currentStatus: LifecycleStatus | BatchStatusName,
): (typeof statusCssClassnames)[keyof typeof statusCssClassnames] {
    const currentStatus_ = currentStatus in BatchStatusName ? lowercase(currentStatus) : currentStatus;
    if (isLifecycleStatus(BatchStatusName.SETTLED, currentStatus_)) {
        return statusCssClassnames.success;
    } else if (isLifecycleStatus(BatchStatusName.ERROR, currentStatus_)) {
        return statusCssClassnames.failed;
    } else if (isLifecycleStatus(BatchStatusName.REJECTED, currentStatus_)) {
        return statusCssClassnames.rejected;
    } else if (isLifecycleStatusOk(currentStatus_)) {
        return statusCssClassnames.processing;
    }

    return statusCssClassnames.failed;
}
